import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTModalCard = _resolveComponent("KTModalCard")!
  const _component_KTNewCardModal = _resolveComponent("KTNewCardModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_KTModalCard, {
      title: "New Card Modal Example",
      description: "Click on the below buttons to launch <br/>a new card example.",
      image: _ctx.getIllustrationsPath('6.png'),
      "button-text": "Add New Card",
      "modal-id": "kt_modal_new_card"
    }, null, 8, ["image"]),
    _createVNode(_component_KTNewCardModal)
  ], 64))
}